import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: 'under-construction' }
  },
  // {
  //   path: "/:catchAll(.*)",
  //   name: "404",
  //   component: () => import("@/views/404.vue"),
  // },

  {
    path: "/em-construcao",
    name: "under-construction",
    component: () => import("@/views/UnderConstruction.vue"),
  },
  // {
  //   path: "/error",
  //   name: "error",
  //   component: () => import("@/views/error.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});


router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
